export default {
  introduction: {
    id: 'navigation_home_seo_introduction_discover_product',
    defaultMessage: 'Discover all of our high tech devices',
  },
  joke: {
    id: 'navigation_home_seo_introduction_joke',
    defaultMessage: '- well organized and perfectly tidy (just like my room)',
  },
}
