import { MarketCountryCode } from '@backmarket/http-api'
/**
 * Creates htmlAttrs properties of the Homepage depending on the current country
 * see https://schema.org/Organization and https://schema.org/Corporation
 */
export const getHtmlAttrsByCountry = (country: MarketCountryCode) => {
  const schemaType =
    country === MarketCountryCode.US ? 'Organization' : 'Corporation'

  return {
    itemscope: 'itemscope',
    itemtype: `http://schema.org/${schemaType}`,
  }
}
