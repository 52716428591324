import { useRequestURL, useRouter } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'

import { ROUTES as PRODUCT_LIST_ROUTES } from '~/scopes/product-list/route-names'

import {
  buildHomeSchema,
  buildSearchBoxSchema,
  prepareJsonLdScript,
} from '../../../../utils/seo/schema'
import { HOME } from '../../route-names'

import translations from './useHomeSchema.translations'

/**
 * Simply returns the schema object for all the Homepages of all countries.
 * https://schema.org/Organization
 */
export const useHomeSchema = (companyName: string) => {
  const searchPath = toNuxtLink({
    type: 'internal',
    name: PRODUCT_LIST_ROUTES.SEARCH_RESULTS,
  })
  const homePath = toNuxtLink({ type: 'internal', name: HOME })
  const { hostname, protocol } = useRequestURL()
  const router = useRouter()
  const i18n = useI18n()

  const homeUrl = `${protocol}//${hostname}${router.resolve(homePath).href}`
  const searchUrl = `${protocol}//${hostname}${router.resolve(searchPath).href}`

  const translationsHomeSchema = {
    schemaOrgaDescription: i18n(translations.schemaOrgaDescription),
    schemaQuentinTitle: i18n(translations.schemaQuentinTitle),
    schemaThibaudTitle: i18n(translations.schemaThibaudTitle),
    schemaVianneyTitle: i18n(translations.schemaVianneyTitle),
    schemaOrgaStreetAddress: i18n(translations.schemaOrgaStreetAddress),
    schemaOrgaAddressLocality: i18n(translations.schemaOrgaAddressLocality),
    schemaOrgaAddressRegion: i18n(translations.schemaOrgaAddressRegion),
    schemaOrgaPostalCode: i18n(translations.schemaOrgaPostalCode),
    schemaOrgaAddressCountry: i18n(translations.schemaOrgaAddressCountry),
    schemaOrgaTelephone: i18n(translations.schemaOrgaTelephone),
  }

  const homeSchema = buildHomeSchema(homeUrl, translationsHomeSchema)
  const searchBoxSchema = buildSearchBoxSchema({
    homeUrl,
    searchUrl,
    companyName,
  })

  const homeSchemaScript = prepareJsonLdScript(homeSchema)
  const searchBoxSchemaScript = prepareJsonLdScript(searchBoxSchema)

  return [searchBoxSchemaScript, homeSchemaScript]
}
