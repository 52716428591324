<template>
  <RevSkipToContent to="#skip-to-content-wrapper" to-anchor-id="#main-content">
    {{ i18n(translations.skipToContent) }}
  </RevSkipToContent>
  <main id="main-content">
    <slot />
  </main>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevSkipToContent } from '@ds/components/SkipToContent'

import translations from './TheMainContent.translations'

const i18n = useI18n()
</script>
