<template>
  <div>
    <div class="mb-32 md:mb-56 md:flex">
      <h2 class="body-1-bold mr-4">
        {{ i18n(translations.introduction) }}
      </h2>
      <span class="body-1">{{ i18n(translations.joke) }}</span>
    </div>

    <ul class="mb-24 flex list-none flex-wrap">
      <li
        v-for="(navFirstCat, index) in navigationWithUrl"
        :key="navFirstCat.id"
        data-test="nav-first-cat"
      >
        <RevLink
          class="mb-8 inline-block"
          :to="navFirstCat.link"
          :underlined="false"
        >
          <h3 class="body-1-bold">
            {{ navFirstCat.titleDisplay }}
          </h3>
        </RevLink>
        <span v-if="index < navigationWithUrl.length - 1" class="mx-8">
          -
        </span>
      </li>
    </ul>

    <ul class="list-none flex-col md:flex-row md:justify-between">
      <li
        v-for="navSecondCat in navigationFromLevelTwo"
        :key="navSecondCat.id"
        class="mb-24 mr-32 flex-col md:inline-flex md:w-1/6"
        data-test="nav-second-cat"
      >
        <RevLink
          class="mb-8 inline-block text-left"
          :to="navSecondCat.link"
          :underlined="false"
        >
          <h3 class="body-1-bold line-clamp-3 text-ellipsis">
            {{ navSecondCat.titleDisplay }}
          </h3>
        </RevLink>

        <ul v-if="navSecondCat.hasChildren" class="list-none">
          <li
            v-for="navThirdCat in navSecondCat.children"
            :key="navThirdCat.id"
            data-test="nav-third-cat"
          >
            <RevLink
              class="text-left"
              :to="navThirdCat.link"
              :underlined="false"
            >
              <span class="body-2">
                {{ navThirdCat.titleDisplay }}
              </span>
            </RevLink>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type {
  HomeNavigationContent,
  NavigationItem,
} from '@backmarket/http-api/src/api-specs-content'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLink } from '@ds/components/Link'

import translations from './HomeNavigation.translations'

export type HomeNavigationProps = {
  // If there is an error on the API call, the navigationSeo would be null
  navigationSeo: HomeNavigationContent
}

const { navigationSeo } = defineProps<HomeNavigationProps>()

const i18n = useI18n()

const hasLink = (item: NavigationItem) => typeof item.link !== 'undefined'

const navigationWithUrl = computed(() => {
  return navigationSeo.filter(
    (item) => hasLink(item) && item.children.filter(hasLink).length > 0,
  )
})

const MAX_CHILDREN = 10

const navigationFromLevelTwo = computed(() => {
  return navigationWithUrl.value
    .flatMap((item) => item.children)
    .map((item) => {
      return {
        ...item,
        hasChildren: item.children.length > 0,
        children: item.children
          .slice(0, MAX_CHILDREN)
          .filter((child) => child.titleDisplay),
      }
    })
})
</script>
