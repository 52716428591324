import { isFooterSocials } from '~/scopes/navigation/components/Footer/FooterContent'
import { useFooterCategories } from '~/scopes/navigation/components/Footer/useFooterCategories/useFooterCategories'

export const useHomeSocialMeta = () => {
  const footer = useFooterCategories()

  if (footer === null) {
    return []
  }

  return footer.reduce((accCategory, footerCategory) => {
    const footerContent = footerCategory.content.filter(
      (content) => content.type === 'social-networks',
    )

    return {
      ...accCategory,
      ...footerContent.reduce((accContent, content) => {
        if (isFooterSocials(content)) {
          return {
            ...accContent,
            ...content.networks,
          }
        }

        return accContent
      }, {}),
    }
  }, {})
}
