export default {
  schemaOrgaDescription: {
    defaultMessage:
      'Back Market is a global marketplace for buying premium refurbished tech or selling or trading-in used electronic devices such as smartphones, laptops, tablets, gaming consoles and more.',
    id: 'schema_organization_description',
  },
  schemaQuentinTitle: {
    defaultMessage: 'Co-Founder & CTO',
    id: 'schema_organization_quentin_title',
  },
  schemaThibaudTitle: {
    defaultMessage: 'Co-Founder & CEO',
    id: 'schema_organization_thibaud_title',
  },
  schemaVianneyTitle: {
    defaultMessage: 'Co-Founder & CCO',
    id: 'schema_organization_vianney_title',
  },
  schemaOrgaStreetAddress: {
    defaultMessage: '199 Rue Championnet',
    id: 'schema_organization_street_address',
  },
  schemaOrgaAddressLocality: {
    defaultMessage: 'Paris',
    id: 'schema_organization_locality',
  },
  schemaOrgaAddressRegion: {
    defaultMessage: 'Ile de France',
    id: 'schema_organization_region',
  },
  schemaOrgaPostalCode: {
    defaultMessage: '75018',
    id: 'schema_organization_postal_code',
  },
  schemaOrgaAddressCountry: {
    defaultMessage: 'France',
    id: 'schema_organization_country',
  },
  schemaOrgaTelephone: {
    defaultMessage: '+33 1 70 77 24 93',
    id: 'schema_organization_telephone',
  },
}
